.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
  background-color: aliceblue;
}

.App-header {
  position: relative;
  z-index: 2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.logo {
  width: 40vw;
  height: auto;
  margin-bottom: 20px;
}

.passLogo {
  width: 10vw;
  height: auto;
  margin-top: 20px;
}

.button-container {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-left: 3vw;
  padding-right: 3vw;
}

.main-menu-button {
  width: 16vw;
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .button-container {
    width: 90vw;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  .main-menu-button {
    width: 80%;
    margin-bottom: 10px;
  }

  .logo {
    width: 80vw;
  }

  .passLogo {
    width: 20vw;
  }
}
